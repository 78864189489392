@import "https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;700&display=swap";
body {
  background: linear-gradient(15deg, #488bf0 0%, #fdc7ff 100%) 0 0 / 100% fixed;
  font-family: Mulish, sans-serif;
  font-size: 11pt;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6, b, strong {
  font-family: Mulish, sans-serif;
  font-weight: 700 !important;
}

.ld-login {
  max-width: 300px;
}

.ld-client-id {
  font-family: monospace;
}

.ld-client-id span {
  vertical-align: middle;
  margin-top: -.3em;
  display: inline-block;
}

.ld-monospace {
  font-family: monospace;
}

.remixicon-icon {
  vertical-align: middle;
  margin-top: -.15em;
  font-size: 1.3em;
  font-weight: 400;
  display: inline-block;
  line-height: 100% !important;
}

svg.remixicon-icon {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-top: -.15em;
  display: inline-block;
}

.ld-quick-wrapper {
  text-decoration: none;
}

.ld-quick {
  color: #2d025bcc;
  width: 300px;
  background-color: #fff6;
  border-radius: 5px 20px 20px;
  margin: .5em;
  padding: 1em;
  font-size: 18pt;
  font-weight: bold;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: block;
}

.ld-quick:hover {
  background-color: #fff9;
  border-radius: 5px;
}

.ld-quick .remixicon-icon {
  margin: -.2em .2em 0 0;
}

.ld-front-header {
  color: #000;
  text-transform: uppercase;
  letter-spacing: .1em;
  text-shadow: 2px 0 3px #0003;
  border-bottom: .15em solid #000;
  font-size: 14pt;
}

.ld-front-header:first-letter {
  font-size: 120%;
}

.ld-time-graph-today {
  width: 5px;
  height: 5px;
  background-color: #0000ff80;
  border-radius: 50%;
  top: 2.5px;
}

.navbar-brand {
  text-shadow: 2px 2px #ffffff1a;
  background: linear-gradient(15deg, #a0d5ff 0%, #d873ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-family: Arial Black, sans-serif;
  font-size: 20pt;
  font-weight: bold;
  line-height: 100%;
  display: inline-block;
}

.btn-xsm {
  padding: 2px 6px;
  font-size: 9pt;
}

.ld-smart-select .ld-select-dropdown {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  background-color: #fff;
  border: 1px solid #00000080;
  border-radius: 0 0 5px 5px;
  padding: 3px;
  line-height: 100%;
  overflow-y: scroll;
  box-shadow: 3px 3px 10px #00000080;
}

.ld-smart-select .ld-select-dropdown.fixed {
  box-shadow: none;
  height: 300px;
  max-height: 300px;
  border-radius: 5px;
}

.ld-smart-select .ld-select-item {
  cursor: pointer;
  border-bottom: 1px solid #0000001a;
  padding: 5px 7px;
  font-size: 9pt;
  display: block;
}

.ld-smart-select .ld-select-item:hover {
  background-color: #8080800d;
}

.ld-smart-select .ld-select-item.active {
  color: #fff;
  background: linear-gradient(0deg, #2243ad 0%, #78b4ff 100%) center / 100% no-repeat;
  border-bottom: 1px solid #2243ad;
  border-radius: 5px;
}

.ld-smart-select:not(.multi) input {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z'/%3E%3C/svg%3E");
  background-position: 100%;
  background-repeat: no-repeat;
}

.ld-front-figure-box {
  width: 100%;
  max-width: 300px;
  background-color: #fff3;
  border-radius: 5px;
  margin: 3px;
  padding: 1em;
}

.ld-front-figure {
  text-shadow: 2px 2px 5px #00000080;
  font-size: 45pt;
  font-weight: 700;
}

.ld-front-figure-title {
  font-weight: 300 !important;
}

.ld-copy-html {
  background: linear-gradient(#0000, #0000001a) 0 0 / cover;
  border: 1px solid #0003;
  border-radius: 3px;
  outline: none;
  padding: 7px;
}

.ld-copy-html:focus {
  border-color: #2d025bcc;
}

.ld-copy-html {
  font-size: 11pt;
  line-height: 110%;
}

.ld-copy-html, .ld-copy-html * {
  font-family: Arial, sans-serif;
}

.ld-copy-html p {
  margin: 0;
  padding: 0;
}

.text-bold {
  font-weight: bold;
}

/*# sourceMappingURL=index.72867d3b.css.map */
