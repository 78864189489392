@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;700&display=swap');

body {
    font-family: 'Mulish', sans-serif;
    font-weight: 300;
    font-size: 11pt;
    background: rgb(72,139,240);
    background: linear-gradient(15deg, rgba(72,139,240,1) 0%, rgba(253,199,255,1) 100%);
    background-attachment: fixed;
    background-size: 100%;
}

h1, h2, h3, h4, h5, h6, b, strong {
    font-family: 'Mulish', sans-serif;
    font-weight: 700 !important;
}

.ld-login {
    max-width: 300px;
}

.ld-client-id {
    font-family: monospace;
}

.ld-client-id span {
    display: inline-block;
    vertical-align: middle;
    margin-top: -0.3em;
}

.ld-monospace {
    font-family: monospace;
}

.remixicon-icon {
    display: inline-block;
    font-size: 1.3em;
    font-weight: 400;
    vertical-align: middle;
    margin-top: -0.15em;
    line-height: 100% !important;
}

svg.remixicon-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    margin-top: -0.15em;
}

.ld-quick-wrapper {
    text-decoration: none;
}

.ld-quick {
    display: block;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 1em;
    margin: 0.5em;
    border-radius: 5px 20px 20px 20px;
    color: rgba(45, 2, 91, 0.8);
    font-size: 18pt;
    text-decoration: none;
    transition: all ease-in-out .2s;
    width: 300px;
    font-weight: bold;
}

.ld-quick:hover {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
}

.ld-quick .remixicon-icon {
    margin: -0.2em 0.2em 0 0;
}

.ld-front-header {
    color: #000000;
    border-bottom: 0.15em solid #000000;
    font-size: 14pt;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-shadow: 2px 0 3px rgba(0, 0, 0, 0.2);
}

.ld-front-header::first-letter {
    font-size: 120%;
}

.ld-time-graph-today {
    background-color: rgba(0, 0, 255, 0.5);
    top: 2.5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

.navbar-brand {
    display: inline-block;
    font-size: 20pt;
    line-height: 100%;
    font-family: 'Arial Black', sans-serif;
    font-weight: bold;
    background: rgb(160,213,255);
    background: linear-gradient(15deg, rgba(160,213,255,1) 0%, rgba(216,115,255,1) 100%);
    text-shadow: 2px 2px 0 rgba(255, 255, 255, 0.1);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.btn-xsm {
    font-size: 9pt;
    padding: 2px 6px;
}


.ld-smart-select .ld-select-dropdown {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow-y: scroll;
    background-color: #ffffff;
    padding: 3px;
    line-height: 100%;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
    
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none;
}

.ld-smart-select .ld-select-dropdown.fixed {
    box-shadow: none;
    height: 300px;
    max-height: 300px;
    border-radius: 5px;
}

.ld-smart-select .ld-select-item {
    display: block;
    padding: 5px 7px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 9pt;
}

.ld-smart-select .ld-select-item:hover {
    background-color: rgba(128, 128, 128, 0.05);
}

.ld-smart-select .ld-select-item.active {
    background: rgb(34,67,173);
    background: linear-gradient(0deg, rgba(34,67,173,1) 0%, rgba(120,180,255,1) 100%);
    border-bottom: 1px solid rgba(34,67,173,1);
    border-radius: 5px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    color: #ffffff;
}

.ld-smart-select:not(.multi) input {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z'/%3E%3C/svg%3E");
    background-position: right center;
    background-repeat: no-repeat;
}

.ld-front-figure-box {
    width: 100%;
    max-width: 300px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 1em;
    border-radius: 5px;
    margin: 3px;
}

.ld-front-figure {
    font-weight: 700;
    font-size: 45pt;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.ld-front-figure-title {
    font-weight: 300 !important;
}

.ld-copy-html {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 7px;
    outline: none;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.1));
    background-size: cover;
}

.ld-copy-html:focus {
    border-color:rgba(45, 2, 91, 0.8);
}

.ld-copy-html {
    font-size: 11pt;
    line-height: 110%;
}

.ld-copy-html, .ld-copy-html * {
    font-family: 'Arial', sans-serif;
}

.ld-copy-html p {
    margin: 0;
    padding: 0;
}

.text-bold {
    font-weight: bold;
}